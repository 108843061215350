.about_carousel_container {
  height: 360px;
  width: 100vw;
  text-align: center;
}

@media (max-width: 900px) {
  .ant-form-item {
    margin: 0 0 5px;
  }
  .ant-message{
    top: 100px;
  }
}


@media  (min-width: 1000px) {
  .ant-form-item {
    margin: 0 0 20px;
  }
}

.ant-input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.ant-select-focused {
  border-color: none;
}

.ant-select-selection-placeholder {
  opacity: 0.3;
  font-size: 16px;
  color: #343744;
  letter-spacing: 0;
  font-weight: 400;
  font-family: PingFangSC-Regular;
}

::placeholder {
  opacity: 0.8;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #343744;
  letter-spacing: 0;
  font-weight: 400;
}

.ant-input:focus {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  box-shadow: none;
}

.ant-form-item-control-input {
  min-height: 48px;
}
