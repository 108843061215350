.arrowRotate {
  transform: rotate(180deg);
}

.arrowRotate_de {
  transform: rotate(720deg);
}

.menu-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60px;
  z-index: 12;
}

.menu_container_item {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-item {
  width: 70px;
  height: 20px;
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
}

.split_line {
  height: 0.5px;
  opacity: 0.15;
  background: #1f2129;
  position: fixed;
  top: 59px;
  width: 100vw;
  z-index: 12;
}

.hard_ware_sub_menu_container {
  width: 100vw;
  height: 400px;
  background-color: #ffffff;
  text-align: center;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 60px;
  z-index: 13;
}

.hard_ware_sub_menu_text {
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #343744;
  letter-spacing: 0;
  font-weight: 400;
}

.hard_ware_sub_menu_subText {
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #343744;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.soft_ware_sub_menu_container {
  width: 100vw;
  height: 400px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 60px;
  z-index: 13;
}

.soft_ware_text_item_container {
  width: 310px;
  height: 200px;
  padding-top: 10px;
}

.soft_ware_split_line {
  margin-right: 27px;
  margin-left: 27px;
}

.soft_ware_split_line {
  width: 0.5px;
  height: 200px;
  opacity: 0.1;
  background: #1f2129;
}

.soft_ware_text {
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1f2129;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
}

.sub_menu_items_text_container {
  width: 48px;
  height: 17px;
  text-align: center;
  background-color: "red";
}

.learn_more {
  width: 56px;
  height: 20px;
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #f1f4f6;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
}

.learn_more_hover {
  width: 56px;
  height: 20px;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #12c194;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
}

.sub_menu_items_text {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #000000;
  opacity: 0.4;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

.sub_menu_filter {
  backdrop-filter: brightness(100%) saturate(100%) blur(20px);
}

.sub_menu_items_text:hover {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #343744;
  opacity: 1;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
}

.sub_menu_items_text_active {
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #12c194;

  opacity: 1;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
}

.sub_menu_ask_button {
  width: 58px;
  height: 27px;
  background: #343744;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_menu_ask_button:hover {
  width: 58px;
  height: 27px;
  background: #12c194;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_menu_ask_text {
  width: 24px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}

.sub_menu_ask_text:hover {
  width: 24px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}

.show_blur_border {
  width: 150px;
}

.show_blur_border:hover {
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(231, 232, 239, 1);
}

.learn_more_radius_container {
  width: 94px;
  height: 32px;
  background: rgba(52, 55, 68, 0.5);
  border-radius: 16px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.learn_more_radius_container:hover {
  width: 94px;
  height: 32px;
  opacity: 1;
  background: #12c194;
  border-radius: 16px;
  position: absolute;
  right: 40px;
  bottom: 40px;
}

.learn_more_radius_text {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
}

.ecology_background{
  background-image: url('../../static/menu/ecology/ecology_background.png');
  background-size: cover;
  background-position: 40%;
}

.hardWare_blur:hover{

}
