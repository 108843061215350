.statistics_container {
  width: 386px;
  height: 260px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 40px;
}

.techology_params_container {
  width: 370px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  background: #f4f8fb;
  border-radius: 15px;
}

.techology_params_container_title {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #343744;
  letter-spacing: 0;
  font-weight: 400;
}

.techology_params_container_desc {
  opacity: 0.5;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #343744;
  letter-spacing: 0;
  font-weight: 400;
}

.serve_mode_container {
  width: 590px;
  height: 320px;
  background: #ffffff;
  border: 4px solid rgba(244, 248, 251, 1);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image-gallery-thumbnails-wrapper{
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: -200px;
  width: 1200px;

}

.image-gallery-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-gallery-thumbnail{
  width: 288px;
  height:143px;
  margin-right: 27px;
  border-radius: 5px;
  border: 4px solid rgba(255,255,255,0.2);;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image{
  width: 280px;
  height: 135px;
  border-radius: 2px;
}

.image-gallery-thumbnail.active{
  border: 4px solid white;
  border-radius: 2px;
}

.image-gallery-thumbnail:hover{
  width: 288px;
  height:143px;
  margin-right: 27px;
}

.image-gallery-container{
  max-height: 740px;
  overflow: hidden;
}

.ping_fang{
  font-family: PingFangSC-Regular, PingFang SC !important
}
