.margin_left_right{
    margin-right: 8px;
    margin-left: 8px;
}

.slide_menu_filter {
  backdrop-filter: brightness(100%) saturate(100%) blur(15px);
}

.adm-page-indicator-dot{
  width: 30px;
  height: 2px;
  border-radius: 1px;
}

.split_line_text{
  opacity: 1 !important
}